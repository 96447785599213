import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { hasValidSession } from '../../api/Api';
import { ApplicationState } from '../../store/index';
import * as AuthStore from '../../store/auth/authReducer';
import { NavLink } from 'react-router-dom';
import * as ReactDom from 'react-dom';
import * as CheckRequestStore from '../../store/checkRequests/checkRequestReducer';
import * as TripStore from '../../store/trips/tripReducer';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { ICheckRequest, ICurrency, ICheckRequestApprover } from '../index';
import { Api } from '../../api/Api';
import CircleProgress from '@material-ui/core/CircularProgress';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { CheckRequestDenyDialog } from './CheckRequestDenyDialog';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import DenyIcon from '@material-ui/icons/NotInterested';
import {
    AppBar, Toolbar, Select, MenuItem, InputLabel, Grid, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, List,
    ListItem, ListItemText, IconButton, Button, Divider, Snackbar, LinearProgress,
    Stepper, Step, StepLabel, Radio, RadioGroup, FormControlLabel, Box
} from '@material-ui/core';
import { ITripState } from '../../store/trips/tripState';
import UserAutocomplete from '../UserAutocomplete';
import { filterUsers } from '../../store/utils/userUtils';
import { BLOB_URL } from '../../constants/constants';

const api = new Api();

enum CheckRequestMode {
    create = 1,
    read,
    update
}

enum CheckRequestStatus {
    draft = 1,
    submitted,
    approved,
    processed
}

function getSteps() {
    return ['Draft', 'Submitted', 'Approved', 'Processed'];
}
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

type ICheckRequestProps = typeof CheckRequestStore.actionCreators
    & CheckRequestStore.ICheckRequestState   
    & ITripState
    & AuthStore.IAuthState
    & typeof TripStore.actionCreators & typeof AuthStore.actionCreators & RouteComponentProps<{}>;

interface ICheckRequestState {
    inputValue: any;
    isDenyOpen: boolean;
    steps: Array<string>;
}
class CheckRequest extends React.Component<ICheckRequestProps, ICheckRequestState> {
    steps = getSteps();
    constructor(props?: any) {
        super(props);

        this.state = {
            inputValue: '',
            isDenyOpen: false,
            steps: ['Draft', 'Submitted', 'Approved', 'Processed']
        }
        this.handleDenyCheckRequest = this.handleDenyCheckRequest.bind(this);
        this.handleDenyClickClose = this.handleDenyClickClose.bind(this);
        this.handleDenyClickOpen = this.handleDenyClickOpen.bind(this);  
        this.openPdfAttachment = this.openPdfAttachment.bind(this);
        this.openPDFDialog = this.openPDFDialog.bind(this);
        this.deleteCheckRequest = this.deleteCheckRequest.bind(this);
        this.openLink = this.openLink.bind(this);
    }

    componentDidMount() {       
        if (hasValidSession()) {
            this.props.initializeApp();
        }

        this.props.getApproverList();
        this.props.handleCreateCheckRequest();
    }
    deleteCheckRequest() {
        if (window.confirm('Are you sure?')) {
            this.props.deleteCheckRequest();
        }

    }
    handleDenyClickClose() {
        this.setState({
            isDenyOpen: false
        });
    }
    handleDenyClickOpen() {
        this.setState({
            isDenyOpen: true
        });
    }
    handleDenyCheckRequest() {
        this.handleDenyClickClose();
        this.props.denyCheckRequest();
    }
    async openLink() {
       
        try {           
            let key = 'Check+Request_Tutorial.pptx';
            const sasToken = await api.getBlobSasToken(key);
            const sasTokenLink = key + sasToken;         
            window.open(`${BLOB_URL}/${sasTokenLink}`, '_blank');
        } catch (error) {
            //console.error('There was an error getting the SAS token:', error);
        } 
    }
    async openPdfAttachment(checkRequest: ICheckRequest) {
       
        try {
            let key = checkRequest.pdfKey;
            const sasToken = await api.getBlobSasToken(key);
            const sasTokenLink = key + sasToken;
            window.open(`${BLOB_URL}/${sasTokenLink}`, '_blank');
        } catch (error) {
            //console.error('There was an error getting the SAS token:', error);
        }       
    }
    openPDFDialog = () => {
        let fileUploadDom: any = ReactDom.findDOMNode(this.refs.pdfUpload);
        fileUploadDom.click();
    }
    
    // if there is no pdf or if the currentUser.email == selectedCheckRequest.requestedByEmail and its not in draft status.    
    shouldHideRemovePDFButton = () => {
        const { selectedCheckRequest, pdfTempFileName } = this.props;
       
        return (!selectedCheckRequest.pdfKey && !pdfTempFileName)
            || (selectedCheckRequest.approvalStatusId !== CheckRequestStatus.draft && this.props.currentUser.email !== selectedCheckRequest.requestedByEmail);
    }

    render() {

        const { classes, selectedCheckRequest, mode, status } = this.props as any;

        const loadUsers = (inputValue, callback) => {
            this.props.loadUserList(inputValue, null);
            callback(filterUsers(this.props.users, inputValue));
        };

        return <div>

            {this.props.currentUser && selectedCheckRequest ? <main>

                <AppBar position='static' className={classes.appBar}>
                    <Toolbar>
                        <IconButton className={classes.menuButton} color='inherit' aria-label='Menu'>
                            <NavLink style={{ color: '#fff' }} to='/' className={classes.noDecorationLink}>
                                <HomeIcon />
                            </NavLink>
                        </IconButton>
                        <Typography variant='h6' color='inherit' className={classes.flex}>
                            <NavLink style={{ color: '#fff' }} to='/' className={classes.noDecorationLink}>SCHOTT Travel Expense App</NavLink>
                            <Typography variant='body1' color='inherit' component='div'>

                                <Box display='flex' alignItems='center' className={classes.padBottom}>
                                    <AccountCircleIcon fontSize='small' />
                                    &nbsp;&nbsp;{this.props.currentUser ? this.props.currentUser.name : ''}
                                </Box>
                            </Typography>
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container justifyContent='center' spacing={4}>
                    <Grid item xs={12} sm={5}>
                        <br />
                        <br />
                        <Paper className={classes.paperList}>
                            <div>
                                <div style={{ float: 'right' }}>
                                    <Typography color='secondary' variant='h6'>{selectedCheckRequest.invoiceDisplay}</Typography>
                                    
                                    <Stepper
                                        orientation='vertical'
                                        activeStep={status - 1}>
                                        {this.steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            const labelProps: { optional?: React.ReactNode } = {};

                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>

                                {/* Header */}
                                <div> 
                                    <div style={{ display: mode === CheckRequestMode.update ? 'inline-block' : 'none' }}>                                       
                                        {/* Close button */}
                                        <Button
                                            style={{ display: selectedCheckRequest.invoiceDisplay ? 'flex' : 'none' }}
                                            variant='outlined'
                                            color='default'
                                            size='large'
                                            disabled={this.props.isSavingCheckRequest}
                                            onClick={(e: any) => this.props.handleCloseCheckRequestApprovalForm()}
                                        >
                                            <Typography variant='caption'>Close</Typography>
                                        </Button>
                                       
                                        <br />
                                    </div>
                                    <br />
                                   
                                  
                                    <Typography variant='h6'>CHECK REQUEST FORM  <span
                                        style={{ display: mode === CheckRequestMode.update ? 'inline-block' : 'none', color: '#f50057' }}>
                                       - EDITING
                                    </span>
                                    </Typography>
                                    {/*<Box display='flex' alignItems='center' className={classes.padBottom}>*/}
                                    {/*    <Button*/}
                                    {/*        onClick={(e: any) => this.openLink()}*/}
                                    {/*        variant='outlined'*/}
                                    {/*        color='primary'>*/}
                                    {/*        Need help? Check Request Tutorial*/}
                                    {/*    </Button>*/}                                       
                                    {/*</Box>*/}
                                    <div className={classes.pad}>
                                        <FormControl>
                                            
                                            <RadioGroup
                                                name='companyCode'
                                                className={classes.group}
                                                value={selectedCheckRequest.companyNumber
                                                    ? selectedCheckRequest.companyNumber.toString() : this.props.currentUser.companyNumber.toString()}
                                                onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'companyNumber')}
                                            >                                              
                                                <FormControlLabel value='67' control={<Radio />} label='SAP 0067 – SCHOTT North America' />
                                                <FormControlLabel value='996' control={<Radio />} label='SAP 996 – SCHOTT MINIFAB' />
                                                <FormControlLabel value='995' control={<Radio />} label='SAP 995 – SCHOTT Pharma USA' />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <br />
                                    <br />
                                    <Typography variant='caption'>Please note: All fields in <span style={{ color: 'red' }}>red</span> are required.</Typography>
                                    <br />
                                    <br />
                                </div>

                                {/* Fields */}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <form className={classes.container} noValidate>
                                        <DatePicker
                                            autoOk={true}
                                            required
                                            emptyLabel={' '}
                                            label='Date:'
                                            format={moment(selectedCheckRequest.checkRequestDate).format('MM/DD/YYYY')}
                                            value={selectedCheckRequest.checkRequestDate}
                                            className={classes.textField}
                                            onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'checkRequestDate')}
                                        />
                                    </form>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                            </div>

                            <FormControl className={classes.select} style={{
                                display: selectedCheckRequest.approvalStatusId === CheckRequestStatus.processed || ((selectedCheckRequest.approvalStatusId === CheckRequestStatus.approved && mode === CheckRequestMode.update)
                                    || (this.props.isController && (selectedCheckRequest.approvalStatusId === CheckRequestStatus.submitted || selectedCheckRequest.approvalStatusId === CheckRequestStatus.approved)))
                                    ? 'none' : 'inherit'
                            }}>
                                <InputLabel shrink>
                                    Send Approval Request To
                                </InputLabel>

                                <Select
                                    required
                                    error={!selectedCheckRequest.approvedByEmail}
                                    className={classes.select}
                                    value={selectedCheckRequest && selectedCheckRequest.approvedByEmail || ''}
                                    onChange={(e: any) => this.props.handleApprovedByEmailChange(e)}
                                >
                                    {/*
                                     * show approver list if the check request is in draft status
                                     */}
                                    {(mode === CheckRequestMode.read || mode === CheckRequestMode.create)
                                        && this.props.approverList && this.props.approverList.map((x: ICheckRequestApprover, $index: number) =>
                                            <MenuItem key={$index} value={x.value}>{x.label}</MenuItem>)}

                                    {selectedCheckRequest.approvalStatusId === CheckRequestStatus.submitted && mode === CheckRequestMode.update
                                        && this.props.approverList && this.props.approverList.map((x: ICheckRequestApprover, $index: number) =>
                                            <MenuItem key={$index} value={x.value}>{x.label}</MenuItem>)}
                                    {/* 
                                     * show controller list if the check request is in approved status
                                     * */}
                                    {(selectedCheckRequest.approvalStatusId === CheckRequestStatus.approved && mode === CheckRequestMode.update)
                                        && this.props.controllerList && this.props.controllerList.map((x: ICheckRequestApprover, $index: number) =>
                                            <MenuItem key={$index} value={x.value}>{x.label}</MenuItem>)}

                                </Select>

                            </FormControl>
                            <Typography variant='body2'>Approver: {selectedCheckRequest.managerApprovedBy}  </Typography>
                            <div className={classes.pad}>
                                <TextField
                                    fullWidth
                                    error={!selectedCheckRequest.payableTo}
                                    id='payableTo'
                                    multiline
                                    required
                                    label='Payable To'
                                    value={selectedCheckRequest.payableTo}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'payableTo')}
                                />
                            </div>
                            <div className={classes.pad}>
                                <TextField
                                    fullWidth
                                    error={!selectedCheckRequest.address}
                                    id='address'
                                    required
                                    label='Address'
                                    value={selectedCheckRequest.address}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'address')}
                                />
                            </div>
                            <div className={classes.pad}>
                                <TextField
                                    fullWidth
                                    multiline
                                    error={!selectedCheckRequest.purpose}
                                    id='purpose'
                                    required
                                    label='Purpose'
                                    value={selectedCheckRequest.purpose}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'purpose')}
                                />
                            </div>
                            <div className={classes.pad}>
                                <TextField
                                    fullWidth
                                    error={!selectedCheckRequest.amount}
                                    color={'primary'}
                                    onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'amount')}
                                    type='number'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            inputMode: 'decimal'
                                        }
                                    }}
                                    value={selectedCheckRequest.amount ? selectedCheckRequest.amount : ''}
                                    label={<Typography color='primary'>($) Amount *</Typography>}
                                />
                            </div>
                            <div className={classes.pad}>
                                <FormControl className={classes.select}>
                                    <InputLabel>Currency</InputLabel>
                                    <Select className={classes.select}
                                        value={selectedCheckRequest.currencyId}
                                        onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'currencyId')}
                                    >

                                        {this.props.currencies.map((c: ICurrency) =>
                                            <MenuItem key={c.currencyId} value={c.currencyId}>{`${c.currencyIcon}  ${c.name}`}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.pad}>
                                <TextField
                                    fullWidth
                                    id='returnTheCheckTo'
                                    label='Return the Check To:'
                                    value={selectedCheckRequest.returnTheCheckTo || ''}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'returnTheCheckTo')}
                                />
                            </div>
                            <div className={classes.pad} style={{ display: this.props.isEditing ? 'none' : 'inherit' }}>
                                <Typography variant='body1'>Requested By</Typography>
                                <Typography variant='caption'>{selectedCheckRequest.requestedByEmail}</Typography>
                                <UserAutocomplete loadUsers={loadUsers} changeUser={(e) => this.props.handleCheckRequestFormValueChange(e, 'requestedBy')} modifier='requestedBy' />
                            </div>
                            <Paper variant='outlined' style={{ padding: 50, marginTop: 20, backgroundColor: '#cccccc14' }}>
                                <Typography variant='caption'>ACCOUNTING USE ONLY</Typography>
                                <Divider />

                                <div className={classes.pad}>
                                    <FormControl className={classes.select}>
                                        <InputLabel>ACH Block</InputLabel>
                                        <Select className={classes.select}
                                            fullWidth
                                            value={selectedCheckRequest.achBlock}
                                            onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'achBlock')}
                                        >
                                            <MenuItem value={'1'}>Yes</MenuItem>
                                            <MenuItem value={'0'}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={classes.pad}>
                                    <TextField
                                        fullWidth
                                        error={mode == CheckRequestMode.update && !(selectedCheckRequest.costCenter || this.props.currentUser.itCostCenter)}                                  
                                        id='costCenter'
                                        label='Cost Center / Order Number'
                                        value={selectedCheckRequest.costCenter || this.props.currentUser.itCostCenter}
                                        margin='normal'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'costCenter')}
                                    />
                                </div>

                                <div className={classes.pad}>
                                    <TextField
                                        fullWidth
                                        error={mode == CheckRequestMode.update && !selectedCheckRequest.costElement}
                                        id='costElement'
                                        label='Cost Element'
                                        value={selectedCheckRequest.costElement}
                                        margin='normal'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'costElement')}
                                    />
                                </div>
                                <div className={classes.pad}>
                                    <TextField
                                        fullWidth
                                        error={mode == CheckRequestMode.update && !selectedCheckRequest.vendorNumber}
                                        id='vendorNumber'
                                        label='Vendor #'
                                        value={selectedCheckRequest.vendorNumber}
                                        margin='normal'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, 'vendorNumber')}
                                    />
                                </div>
                            </Paper>
                            <br />
                            <br />

                            {/* PDF options */}
                            <Paper variant='outlined'>
                                <Typography variant='subtitle2' className={classes.bottomNav}>Supporting material</Typography>
                                <Typography variant='caption' className={classes.bottomNav}>Attachment must be in PDF format.
                                    <span style={{
                                        color:
                                            (selectedCheckRequest.pdfKey || this.props.pdfTempFileName) ? 'green' : 'red'
                                    }}>&nbsp;*(required)</span></Typography>
                                <Divider />
                                <div className={classes.paperList}>

                                    {/* Attach PDF button */}
                                    <Button
                                        style={{
                                            display: (!selectedCheckRequest.pdfKey && !this.props.pdfTempFileName) ?
                                                'inline-block' : 'none'
                                        }}
                                        className={classes.btn}
                                        variant='outlined'
                                        disabled={this.props.isSavingCheckRequest}
                                        onClick={() => this.openPDFDialog()}>

                                        <Typography variant='caption' style={{
                                            color:
                                                (selectedCheckRequest.pdfKey || this.props.pdfTempFileName) ? 'green' : 'red'
                                        }}>
                                            Attach PDF
                                    </Typography>
                                    </Button>


                                    {/* View PDF */}
                                    <Typography variant='caption' color='primary'>
                                        <Typography variant='caption' color='secondary'>
                                            {this.props.pdfTempFileName} </Typography>
                                        <PdfIcon
                                            style={{ visibility: selectedCheckRequest.checkRequestId && selectedCheckRequest.pdfKey ? 'visible' : 'hidden', fontSize: '30px' }}
                                            className='pointer'
                                            onClick={(e: any) => this.openPdfAttachment(selectedCheckRequest)}
                                        />
                                    </Typography>

                                   {/* Remove PDF button  */}
                                    <Button
                                        style={{
                                            display: this.shouldHideRemovePDFButton() ? 'none' : 'inline-block'
                                        }}
                                        className={classes.btn}
                                        disabled={this.props.isSavingCheckRequest}
                                        onClick={this.props.removeCheckRequestPdfAttachment}>

                                        <Typography variant='caption' color='primary'>
                                            Remove PDF
                                      </Typography>
                                    </Button>

                                </div>
                            </Paper>

                            {/* Buttons */}
                            <div className={classes.pad} style={{ textAlign: 'center' }}>

                                <Grid container>
                                    <Grid style={{ display: this.props.isSavingCheckRequest ? 'inline-block' : 'none' }} item xs={12}>
                                        <CircleProgress color='secondary' />
                                    </Grid>

                                    {status == CheckRequestStatus.draft &&

                                        <React.Fragment>
                                            <Divider />

                                            <Grid item xs={12}>

                                                {/* 
                                                * If it's in Draft mode because it was denied, 
                                                * update it rather then create new (see onClick event). We can tell it was denied if it already has an invoice #.
                                                * 
                                                * */}
                                                <Button
                                                    className={classes.btn}
                                                    disabled={this.props.isSavingCheckRequest || !this.props.isCheckRequestValid()}
                                                    variant='contained'
                                                    onClick={() => this.props.selectedCheckRequest.invoiceDisplay ?
                                                        this.props.updateCheckRequest() : this.props.saveCheckRequest()}>
                                                    <Typography variant='caption' color='primary'>
                                                        Submit for approval
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    }

                                    {mode === CheckRequestMode.update
                                        && (status === CheckRequestStatus.submitted && !this.props.isController) &&

                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Button className={classes.btn}
                                                    disabled={this.props.isSavingCheckRequest || !this.props.isCheckRequestValid()}
                                                    color='primary' size='large' variant='outlined' onClick={this.props.approveCheckRequest} >
                                                    <Typography variant='caption' color='secondary'>
                                                        Approve (w/ Edits)</Typography>
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    }


                                    {mode === CheckRequestMode.update
                                        && (status === CheckRequestStatus.approved || (status === CheckRequestStatus.submitted && this.props.isController)) &&

                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Button className={classes.btn} disabled={this.props.isSavingCheckRequest || !this.props.isCheckRequestValid()}
                                                    variant='outlined' size='large' color='primary' onClick={this.props.approveCheckRequestController} >
                                                    <Typography variant='caption' color='secondary'>
                                                        Approve (send to Payables)</Typography>
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    }

                                    {mode === CheckRequestMode.update &&
                                        (status === CheckRequestStatus.submitted || status === CheckRequestStatus.approved) &&

                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Button className={classes.btn}
                                                    type='button'
                                                    variant='outlined'
                                                    startIcon={<DenyIcon />}
                                                    onClick={this.handleDenyClickOpen}
                                                >
                                                    <Typography variant='caption'>Deny</Typography>
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                    <Divider />
                                    <br />
                                    <br />

                                    {/* Close */}
                                    <Grid item xs={12}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            size='large'
                                            className={classes.btn}
                                            disabled={this.props.isSavingCheckRequest}
                                            onClick={(e: any) => this.props.handleCloseCheckRequestApprovalForm()}
                                        >
                                            <Typography variant='caption'>Close</Typography>
                                        </Button>
                                    </Grid>

                                    <Divider />
                                    <br />


                                </Grid>
                            </div>

                            <br />
                            <br /><br />

                     
                             {/*Debug line for testing:*/}
                             {/*   <Typography variant='caption'>debug: controller: {this.props.isController ? 'y' : 'n'}, mode: {CheckRequestMode[mode].toString()}, status: {CheckRequestStatus[status].toString()}</Typography>*/}
                                
                           
                            <br /><br />
                            <div style={{ textAlign: 'center' }}>

                                <Grid item xs={12} style={{
                                    visibility: this.props.selectedCheckRequest && this.props.selectedCheckRequest.checkRequestId
                                        && status !== CheckRequestStatus.processed ? 'visible' : 'hidden'
                                }}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        size='small'
                                        className={classes.btn}
                                        startIcon={<DeleteIcon />}
                                        disabled={this.props.isSavingCheckRequest}
                                        onClick={this.deleteCheckRequest}
                                    >
                                        <Typography variant='caption'>DELETE</Typography>
                                    </Button>
                                </Grid>

                            </div>
                            <Divider />
                        </Paper>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <br /><br />                       
                        <div style={{ margin: 10 }}>
                            {/* Pending Approval Check Requests */}
                            <Paper className={classes.paperList}>
                                <Typography color='secondary' variant='h6'>Pending Your Approval</Typography>
                                <Divider />
                                {this.props.isLoading
                                    ? <LinearProgress />
                                    : this.props.checkRequestsToApprove.length > 0 ? <List className={classes.list}>

                                        {this.props.checkRequestsToApprove.map((c: ICheckRequest) =>
                                            <div key={c.checkRequestId}>
                                                <ListItem value={c.checkRequestId} onClick={(e: any) => this.props.handleOpenCheckRequestApprovalForm(c)}>
                                                    <ListItemText
                                                        primary={<Typography
                                                            color='primary'
                                                            variant='subtitle1'>{`#${c.invoiceDisplay} for $${c.amount}`}
                                                        </Typography>}
                                                        secondary={`${c.approvalStatusName} ${moment(c.checkRequestDate).format('MM/DD/YY hh:mm a')}.`} />
                                                    <span style={{ textDecoration: 'underline' }}>details</span>
                                                </ListItem>

                                                {/* PDF */}
                                                <ListItem style={{ 'display': c.pdfKey ? 'inherit' : 'none' }}>
                                                    <PdfIcon
                                                        className='pointer'
                                                        onClick={(e: any) => this.openPdfAttachment(c)}
                                                        style={{ 'color': '#e31110', 'height': '20px', 'width': '24px' }} />
                                                </ListItem>
                                            </div>
                                        )}
                                    </List> : <Typography color='primary' variant='subtitle1'>No pending requests.</Typography>
                                }
                            </Paper>
                        </div>
                        <br /> <br />
                        <div style={{ margin: 10 }}>
                            {/* Recent Check Requests */}
                            <Paper className={classes.paperList} style={{ overflowX: 'scroll' }}>
                                <Typography color='secondary' variant='h6'>Your Recent Requests</Typography>
                                <Divider />
                                {this.props.isLoading
                                    ? <LinearProgress />
                                    : this.props.checkRequestsOwnedByUser.length > 0 ? <TableContainer component={Paper}>
                                        <Table className={classes.table} size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Amount/Date</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.checkRequestsOwnedByUser.map((c: ICheckRequest) =>
                                                    <TableRow key={c.checkRequestId}
                                                        onClick={(e: any) => this.props.handleViewCheckRequest(c)}>
                                                        <TableCell>
                                                            <Typography color='secondary' style={{ textDecoration: 'underline', cursor: 'pointer' }}>{c.invoiceDisplay}</Typography>
                                                        </TableCell>
                                                        <TableCell>${c.amount} on   {moment(c.checkRequestDate).format('MM/DD/YY hh:mm a')}</TableCell>


                                                        <TableCell>
                                                            <Typography style={{
                                                                color: c.approvalStatusName === 'Draft' ? '#000'
                                                                    : (c.approvalStatusName === 'Approved' || c.approvalStatusName === 'Processed') ? '#24a690'
                                                                        : '#ED8000'
                                                            }}>
                                                                {c.approvalStatusName}
                                                            </Typography>

                                                            to:  {c.managerApprovedBy}
                                                        </TableCell>
                                                        <TableCell>
                                                            {/* PDF */}
                                                            <PdfIcon
                                                                style={{ 'display': c.pdfKey ? 'inherit' : 'none', 'color': '#e31110', 'height': '20px', 'width': '24px' }}
                                                                className='pointer'
                                                                onClick={(e: any) => this.openPdfAttachment(c)}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                        : <Typography color='primary' variant='subtitle1'>No recent requests.</Typography>
                                }
                            </Paper>
                        </div>
                    </Grid>
                </Grid>

                {/* Dialog for denying check requests */}
                <CheckRequestDenyDialog
                    handleDenyCheckRequest={this.handleDenyCheckRequest}
                    handleDenyClickClose={this.handleDenyClickClose}
                    handleDenyClickOpen={this.handleDenyClickOpen}
                    isDenyOpen={this.state.isDenyOpen}
                    checkRequest={this.props.selectedCheckRequest}
                    handleCheckRequestFormValueChange={this.props.handleCheckRequestFormValueChange}
                />

                {/* Hidden PDF Upload */}
                <input style={{ 'visibility': 'hidden' }} id='pdfFileInput' ref='pdfUpload' type='file' accept='application/pdf'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.uploadPdfAttachment(e.target.files, this.props.selectedCheckRequest)} />

                {/* Messages */}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.props.showMessage || this.props.showValidationError}
                    autoHideDuration={5000}
                    onClose={this.props.handleMessageClose}
                >
                    <Alert onClose={this.props.handleMessageClose} severity={this.props.showValidationError ? 'error' : 'success'}>
                        {this.props.message}
                    </Alert>
                </Snackbar>
            </main>
                : null
            }
        </div>;
    }
}
const styles = (theme: Theme) => createStyles({
    pad: {
        paddingTop: '20px'
    },
    paperList: {
        height: '100%',
        padding: '20px'
    },
    textfield: {
        width: 200,
    },
    appBar: {
        backgroundColor: theme.palette.primary.main
    },
    bottomNav: {
        height: 'auto',
        padding: '20px 20px 5px 20px'
    },
    menuButton: {
        marginRight: 16,
        marginLeft: -12,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    card: {
        width: '100%',
        backgroundColor: '#000'
    },
    select: {
        width: '200px'
    },
    list: {
        listStyle: 'none',
        cursor: 'pointer'
    },
    btn: {
        margin: 30
    },
    noDecorationLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), connect(
    (state: ApplicationState) => state.checkRequest,
    CheckRequestStore.actionCreators
), withStyles(styles))(CheckRequest) as any;